import { v4 as uuidv4 } from 'uuid';
import { ITransmittalListItem, ITransmittalWithdrawStepItem } from "@api";
import TransmittalUpdateBase from "./TransmittalUpdateBase";
import TransmittalWithdrawDetail from "./transmittalWithdrawDetail";

export default class TransmittalWithdrawStep
  extends TransmittalUpdateBase
  implements ITransmittalWithdrawStepItem
{
  public transmittal_withdraw: TransmittalWithdrawDetail;

  constructor(contractId: string, item: ITransmittalListItem) {
    super(contractId, item);

    this.transmittal_withdraw = new TransmittalWithdrawDetail();

    this.tempId = uuidv4();
  }
}
