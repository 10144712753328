/* eslint-disable import/no-extraneous-dependencies */
import { FileModel } from "@aecom/core";
import { Component, Input } from "@angular/core";
import { ITransmittalDocumentDownload, ITransmittalListItem, TransmittalFlow, TransmittalIssued } from "@api";
import TransmittalStatus from "@models/TransmittalStatus";
import LocalContractUserService from "@services/local-contractUser.service";
import { getDateFormatted_MMDDYYYY, getFilesByType, getFormatedDate, getKeyWordsArray } from "@shared/utils";

interface WorkflowUpdatesLineItem {
  name: string;
  value: string | boolean | WorkflowModifiedItem;
  type: string;
}

interface WorkflowModifiedItem {
  add: string[];
  delete: string[];
}

@Component({
  selector: "workflowHistory",
  templateUrl: "./workflowHistory.component.html",
  styleUrls: ["./workflowHistory.component.scss"],
})
export class WorkflowHistoryComponent {
  @Input() transmittal: ITransmittalListItem;

  @Input() docs: ITransmittalDocumentDownload[];

  data: (TransmittalFlow & {
    showItems: WorkflowUpdatesLineItem[];
    collapseItems: WorkflowUpdatesLineItem[]; 
    isVoid: boolean;
    Collapse?: boolean;
    Notes?: string;
    Attachments?: FileModel[];
  } )[];

  constructor(public localContractUserService: LocalContractUserService){}

  ngOnInit(): void {
    if(this.transmittal.transmittal_flow?.length > 0)
    {
      this.data = this.transmittal.transmittal_flow.sort(
        (a, b) =>
          new Date(a.DateReceived).getTime() - new Date(b.DateReceived).getTime(),
      ).filter((f)=>{return f.Status !== TransmittalStatus.Draft}).map((f,i, arr)=>{
        if(f.Status === TransmittalStatus.Void)
        {
          let attachments: FileModel[] = [];
          let notes: string = "";
          const voidItem = this.transmittal.transmittal_withdraw.find((item)=>{return item.SubmittedDate === f.DateReceived});
          if(voidItem)
          {
            notes=voidItem.Notes;
            const documnetsForThis = this.docs.filter((doc) => {
              return doc.ReferenceId === voidItem.Guid;
            });
            if(documnetsForThis?.length > 0)
            {
              attachments = getFilesByType(documnetsForThis);
            }
          }
          return {...f, isVoid: true, showItems: [], collapseItems: [], Collapse: true, Notes: notes, Attachments: attachments};
        } else {
          let lineItems: WorkflowUpdatesLineItem[] = [];
          const issueItem = this.transmittal.transmittal_issued.find((item)=>{return item.DateUpdated === f.DateReceived});
          if(i===0)
          {
            const addCCs = this.transmittal.transmittal_cc_user.filter((cc)=>{return new Date(cc.DateCreated).getTime() <= new Date(f.DateReceived).getTime() && (cc.DateDeleted === null || new Date(cc.DateDeleted).getTime() > new Date(f.DateReceived).getTime())}).map((cc)=>cc.UserId);
            lineItems = this.generateUpdateLineItems(addCCs,[],issueItem,undefined);
          } else {
            const prevIssueItem = this.transmittal.transmittal_issued?.find((item)=>{return item.DateCreated === arr[i-1].DateReceived});
            const addCCs = this.transmittal.transmittal_cc_user.filter((cc)=>{return cc.DateCreated === f.DateReceived}).map((cc)=>cc.UserId);
            const deleteCCs = this.transmittal.transmittal_cc_user.filter((cc)=>{return cc.DateDeleted === f.DateReceived}).map((cc)=>cc.UserId);
            lineItems = this.generateUpdateLineItems(addCCs,deleteCCs,issueItem,prevIssueItem);
          }
          
          return {...f, isVoid: false,
            showItems: lineItems.length > 2 ? lineItems.slice(0, 1) : lineItems.slice(0, 2),
            collapseItems: lineItems.length > 2 ? lineItems.slice(1, lineItems.length) : [],
            Collapse: lineItems.length > 2 ? true : undefined
          }
        }
        
      });
    } else {
      this.data = [];
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  generateUpdateLineItems(addCCs: string[], deleteCCs: string[], currentItem: TransmittalIssued | undefined, prevItem: TransmittalIssued | undefined): WorkflowUpdatesLineItem[] {
    const result: WorkflowUpdatesLineItem[] = [];
    if(prevItem && currentItem)
    {
      if(currentItem.RequireResponse !== prevItem.RequireResponse) {
        result.push({name: "Response Requested:", value: currentItem.RequireResponse, type:"bool"})
      }
      if(currentItem.DueDate !== prevItem.DueDate) {
        result.push({name: "DueDate:", value: currentItem.RequireResponse ? currentItem.DueDate : "", type: "date"})
      }
      if(currentItem.ReceivedToday !== prevItem.ReceivedToday) {
        result.push({name: "Received Today:", value: currentItem.ReceivedToday, type:"bool"})
      }
      if(currentItem.ReceivedDate !== prevItem.ReceivedDate) {
        result.push({name: "Received Date:", value: currentItem.ReceivedToday ? "" : currentItem.ReceivedDate, type: "date"})
      }
      if(currentItem.IsConfidential !== prevItem.IsConfidential) {
        result.push({name: "Confidential:", value: currentItem.IsConfidential, type: "bool"})
      }
      if(currentItem.Topics !== prevItem.Topics) {
        const currentArray = getKeyWordsArray(currentItem.Topics);
        const prevArray = getKeyWordsArray(prevItem.Topics);
        const addKey = currentArray.filter((key)=>{return prevArray.findIndex((prev)=>{return prev === key}) < 0});
        const deleteKey = prevArray.filter((key)=>{return currentArray.findIndex((curr)=>{return curr === key}) < 0});
        result.push({name: "Keywords", value: {add: addKey, delete: deleteKey}, type: "key"})
      }
    }
    else if(currentItem) {
      if(currentItem.RequireResponse !== null) {
        result.push({name: "Response Requested:", value: currentItem.RequireResponse, type:"bool"})
      }
      if(currentItem.DueDate !== null) {
        result.push({name: "DueDate:", value: currentItem.DueDate, type: "date"})
      }
      if(currentItem.ReceivedToday !== null) {
        result.push({name: "Received Today:", value: currentItem.ReceivedToday, type:"bool"})
      }
      if(currentItem.ReceivedDate !== null) {
        result.push({name: "Received Date:", value: currentItem.ReceivedDate, type: "date"})
      }
      if(currentItem.IsConfidential !== null) {
        result.push({name: "Confidential:", value: currentItem.IsConfidential, type: "bool"})
      }
      if(currentItem.Topics !== null) {
        const currentArray = getKeyWordsArray(currentItem.Topics);
        result.push({name: "Keywords", value: {add: currentArray, delete: []}, type: "key"})
      }
    }
    if(addCCs.length > 0 || deleteCCs.length > 0)
    {
      result.push({name:"CC:",value: {add:addCCs,delete:deleteCCs},type:"cc"});
    }
    return result;
  }

  getUserName(id: string): string {
    return this.localContractUserService.getUserById(id)?.UserInfo?.displayName ?? "";
  }

  getFormatedDateMMDDYYYYY(date: string): string {
    return getDateFormatted_MMDDYYYY(date);
  }

  sortKeyWords(values?: string): string[] {
    return getKeyWordsArray(values);
  }

  getFormatedDateAndTime(date: string): string {
    return getFormatedDate(date);
  }
}
