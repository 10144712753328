/* eslint-disable class-methods-use-this */
import { Component, OnInit, HostListener } from "@angular/core";
import { ThemeService } from "@aecom/core";
import { NavigationEnd, Router } from "@angular/router";
import { PreviousRouteService } from "@services/previousRoute.service";
import { filter } from "rxjs/operators";
import environment from "src/environments/environment";
import { AuthService } from "@bbj/components";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export default class AppComponent implements OnInit {
  title = "DOT";

  projects;

  mainheight: unknown;

  isdisabledBtn = true;

  previousUrl: string = null;

  currentUrl: string = null;

  isIframe = false;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.mainheight = window.innerHeight;
  }

  constructor(
    public themeService: ThemeService,
    private router: Router,
    private urlService: PreviousRouteService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.initService(environment.stage, environment.azureNew.tenantId);
    this.mainheight = window.innerHeight;
    this.themeService.setActiveFullTheme("light");
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        console.log("PreviousUrl:", this.previousUrl);
        console.log("currentUrl:", this.currentUrl);
        this.urlService.setPreviousUrl(this.previousUrl);
      });
  }
}
