<aecom-form *ngIf="isLoaded" columns="12" class="contentcontainer">
  <aecom-form-group columnSpan="12" columns="2" title="General" style="margin-bottom: 40px;">
    <aecom-form-item [label]="'From (Originator)'" columnSpan="1">
      <div class="itemText">
        {{ orignator }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Issue Date'" columnSpan="1">
      <div class="itemText">
        {{ letterDate }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'To (Recipient)'" columnSpan="1">
      <div class="itemText">
        {{ recipent }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Organization Reference Number'" columnSpan="1">
      <div class="itemText">
        {{ organizationReferenceNumber }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Document Type'" columnSpan="1">
      <div class="itemText">
        {{ documentType }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Transmittal Detail'" columnSpan="1" *ngIf="transmittalDetails">
      <div class="itemText">
        {{ transmittalDetails }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Reference'" columnSpan="2">
      <div class="itemText">
        {{ reference }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Subject/Title'" columnSpan="2">
      <div class="itemText">
        {{ subject }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Additional Notes'" columnSpan="2">
      <div class="itemText">
        {{ note }}
      </div>
    </aecom-form-item>
    <aecom-form-item columnSpan="1">
      <bbj-file-list
        title="Transmittal File"
        [fileList]="transmittalFile"
        [maxCharacters]="maxCharacters"
        [allowMovingFiles]="false"
      ></bbj-file-list>
    </aecom-form-item>
    <aecom-form-item columnSpan="1">
      <bbj-file-list
        title="Attachments"
        [fileList]="attachments"
        [maxCharacters]="maxCharacters"
        [allowMovingFiles]="false"
      ></bbj-file-list>
    </aecom-form-item>
  </aecom-form-group>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>
