/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */
import { ISelectItem, ModalContainerService } from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import TransmittalListRowItem from "@models/transmittalListViewModel";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import * as _ from "lodash";
import { ITransmittalListItem, PrintPDFService } from "@api";
import TransmittalRole from "@models/TransmittalRole";
import { isFinalStep } from "@shared/utils";
import IListPrepare from "@models/IListPrepaer";
import LocalTransmittalDocumentTypeService from "@services/local-transmittalDocumentType.service";
import LocalTransmittalDetailsService from "@services/local-transmittalDetails.service";
import NavService from "@services/nav.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export default class ListComponent implements OnInit {
  @HostListener("window:beforeprint", ["$event"])
  // eslint-disable-next-line class-methods-use-this
  onBeforePrint(event): void {
    this.fixedheadercss = false;
  }

  @HostListener("window:afterprint", ["$event"])
  // eslint-disable-next-line class-methods-use-this
  onAfterePrint(event): void {
    this.fixedheadercss = true;
  }

  subtitle = "Design Builder";

  showCreateBtn: boolean;

  showOrgDD: boolean;

  tableRows: TransmittalListRowItem[] = [];

  tableData: TransmittalListRowItem[] = [];

  contractId: string;

  contractCode: string;

  entities: ITransmittalListItem[] = [];

  transmittalFilter: ISelectItem[] = [
    {
      checked: false,
      id: "open",
      name: "Open",
    },
    {
      checked: false,
      id: "closed",
      name: "Closed",
    },
    {
      checked: true,
      id: "default",
      name: "All Transmittal",
    },
  ];

  fromToFilter: ISelectItem[] = [];

  documentTypeFilter: ISelectItem[] = [];

  fixedheadercss = true;

  searchText: string;

  fromToFilterId: string = null;

  documentTypeFilterId: string = null;

  widthId = 220;
  widthDate = 130;
  widthAction = 150;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public localContractService: LocalContractService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public printPDFService: PrintPDFService,
    public navService: NavService,
    public localTransmittalDocumentTypeService: LocalTransmittalDocumentTypeService,
    public localTransmittalDetailsService: LocalTransmittalDetailsService,
  ) {}

  ngOnInit(): void {
    const data: IListPrepare | undefined =
      this.activatedRoute.snapshot.data.listItems;
    const user = this.navService.getUser();
    this.entities = data.items;
    [...new Set(data.refUsers.map((ref) => ref.Organization))]
      .sort()
      .forEach((org) => {
        this.fromToFilter.push({
          checked: false,
          id: `From: ${org}`,
          name: `From: ${org}`,
        });
        this.fromToFilter.push({
          checked: false,
          id: `To: ${org}`,
          name: `To: ${org}`,
        });
      });
    this.fromToFilter.unshift({
      checked: true,
      id: "default",
      name: "All Organization",
    });
    this.documentTypeFilter = this.localTransmittalDocumentTypeService
      .getItems()
      .map((item) => {
        return { checked: false, id: item.Guid, name: item.Title };
      });
    this.documentTypeFilter.unshift({
      checked: true,
      id: "default",
      name: "All Document Types",
    });
    const role = this.localContractUserService.currentUserContractRole;
    this.showCreateBtn =
      role === TransmittalRole.DBAdmin ||
      role.includes(TransmittalRole.DocControl);
    this.showOrgDD = role !== TransmittalRole.DBAdmin;
    this.contractId = this.localContractService.currentContract.Guid;
    this.contractCode = this.localContractService.currentContract.Code;
    this.subtitle = ListComponent.getUserTitle(role);
    this.tableData = this.entities
      .map((item) => {
        return new TransmittalListRowItem(
          item,
          role,
          user,
          this.localTransmittalDocumentTypeService.getItems(),
          this.localTransmittalDetailsService.getItems(),
          this.localContractUserService,
        );
      })
      .sort((a, b) => b.initSort - a.initSort)
      .sort((a, b) => (a.HideBtn ? 1 : 0) - (b.HideBtn ? 1 : 0));
    this.loadingService.stop();
  }

  private static getUserTitle(role: string): string {
    switch (role) {
      case TransmittalRole.DBAdmin:
        return "Design Builder";
      case TransmittalRole.DocControl:
        return "Doc Control";
      case TransmittalRole.Manager:
        return "Project Manager";
      default:
        return "User";
    }
  }

  createTransmittal(): void {
    this.router.navigateByUrl(`${this.contractId}/create/new`);
  }

  filterData() {
    const searchVar = this.searchText.trim().toLowerCase().split(" ");
    this.tableData.forEach((i) => {
      const searchableValue =
        `${i.TransmittalNumber},${i.OrganizationRef},${i.Subject},${i.ReferenceNumber}_${i.FromOrg},${i.ToOrg},${i.KeyWords},${i.Status}`.toLowerCase();
      i.IsHidden = !searchVar.every((term) => {
        return searchableValue.includes(term);
      });
    });
    this.fromToFilterId = null;
    this.documentTypeFilterId = null;
    // this.updateTable();
  }

  changeTransmittalFilter(e: ISelectItem): void {
    if (!Array.isArray(e)) {
      switch (e.id) {
        case "open":
          this.tableData.forEach((i) => {
            i.IsHidden = isFinalStep(i.Status);
          });
          break;
        case "closed":
          this.tableData.forEach((i) => {
            i.IsHidden = !isFinalStep(i.Status);
          });
          break;
        default:
          this.tableData.forEach((i) => {
            i.IsHidden = false;
          });
          break;
      }
    }
  }

  changeFromToFilter(e: ISelectItem): void {
    if (!Array.isArray(e)) {
      if (e.name && e.id != null && e.id !== "default") {
        const [fromTo, org] = e.id?.split(": ");
        if (fromTo === "From") {
          this.tableData.forEach((i) => {
            i.IsHidden = i.FromOrg !== org;
          });
        } else if (fromTo === "To") {
          this.tableData.forEach((i) => {
            i.IsHidden = i.ToOrg !== org;
          });
        }
      } else {
        this.tableData.forEach((i) => {
          i.IsHidden = false;
        });
      }
      this.searchText = "";
      this.documentTypeFilterId = null;
    }
  }

  changeDocumentTypeFilter(e: ISelectItem): void {
    if (!Array.isArray(e)) {
      if (e.name && e.id != null && e.id !== "default") {
        this.tableData.forEach((i) => {
          i.IsHidden = i.TransmittalDocType !== e.name;
        });
      } else {
        this.tableData.forEach((i) => {
          i.IsHidden = false;
        });
      }
      this.searchText = "";
      this.fromToFilterId = null;
    }
  }

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  download(): void {
    const contractUsers = this.localContractUserService.getItems();
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions;
    const now = new Date()
      .toLocaleDateString(undefined, options)
      .replace(", ", "-")
      .replace(" ", "-");
    const fileName = `BBJ-${this.contractCode ?? ""}-transmittal-${now}.xlsx`;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Logs");
    worksheet.addTable({
      name: "MyTable",
      ref: "A1",
      headerRow: true,
      style: {
        theme: "TableStyleMedium4",
        showRowStripes: true,
      },
      columns: [
        { name: "Transmittal #", filterButton: true },
        { name: "Org Ref #", filterButton: true },
        { name: "Subject", filterButton: true },
        { name: "Reference", filterButton: true },
        { name: "From Organization", filterButton: true },
        { name: "From", filterButton: true },
        { name: "To Organization", filterButton: true },
        { name: "To", filterButton: true },
        { name: "Letter Date", filterButton: true },
        { name: "Key Words", filterButton: true },
        // { name: "Received Date", filterButton: true },
        // { name: "Due Date", filterButton: true },
        { name: "Status", filterButton: true },
        { name: "Transmittal Type", filterButton: true },
        { name: "Transmittal Detail", filterButton: true },
        { name: "Is Confidential", filterButton: true },
      ],
      rows: this.tableRows.map((d) => {
        return [
          d.TransmittalNumber,
          d.OrganizationRef,
          d.Subject,
          d.ReferenceNumber,
          d.FromOrg,
          d.From,
          d.ToOrg,
          d.To,
          d.LetterDateLog,
          ListComponent.capitialize(d.KeyWords),
          // d.ReceivedDate,
          // d.DueDate,
          d.StatusText,
          d.TransmittalDocType ?? "",
          d.TransmittalDetail ?? "N/A",
          d.IsConfidential
        ];
      }),
    });
    // if (this.isDBAdmin) {
    //   worksheet.getColumn(12).alignment = { wrapText: true };
    //   worksheet.getColumn(13).alignment = { wrapText: true };
    // } else {
    //   worksheet.getColumn(13).alignment = { wrapText: true };
    //   worksheet.getColumn(14).alignment = { wrapText: true };
    //   worksheet.getColumn(16).alignment = { wrapText: true };
    // }
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length + 2 : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength > 20 ? 20 : maxLength;
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, fileName);
    });
  }

  // static capitialize(str: string | undefined): string {
  //   if (str) {
  //     return str
  //       .trim()
  //       .split(" ")
  //       .map((s) => {
  //         if (s.length > 0) {
  //           return s.charAt(0).toUpperCase() + s.slice(1);
  //         }
  //         return "";
  //       })
  //       .join(" ");
  //   }
  //   return "";
  // }

  static capitialize(str: string[]): string {
    if (str.length > 0) {
      return str
        .map((s) => {
          if (s.trim().length > 0) {
            return s.charAt(0).toUpperCase() + s.slice(1);
          }
          return "";
        })
        .join(", ");
    }
    return "";
  }
}
