<aecom-form columns="12" class="contentcontainer">
  <aecom-form-group columnSpan="12" columns="4" title="Processing Metadata" style="margin-bottom: 40px;">
    <aecom-form-item label="Response Requested" columnSpan="1">
      <div class="itemText" *ngIf="item; else notProvide">
        {{ item.RequireResponse ? 'Yes' : 'No' }}
      </div>
    </aecom-form-item>
    <aecom-form-item label="Due Date" columnSpan="1" *ngIf="item?.RequireResponse; else hideItem">
      <div class="itemText" *ngIf="item.DueDate; else notProvide">
        {{ getFormatedDate(item.DueDate) }}
      </div>
    </aecom-form-item>
    <aecom-form-item label="Received Today" columnSpan="1">
      <div class="itemText" *ngIf="item; else notProvide">
        {{ item.ReceivedToday ? 'Yes' : 'No' }}
      </div>
    </aecom-form-item>
    <aecom-form-item label="Received Date" columnSpan="1" *ngIf="item?.ReceivedToday === false; else hideItem">
      <div class="itemText" *ngIf="item.ReceivedDate; else notProvide">
        {{ getFormatedDate(item.ReceivedDate) }}
      </div>
    </aecom-form-item>
    <aecom-form-item label="Confidential" columnSpan="2">
      <div class="itemText" *ngIf="item; else notProvide">
        {{ item.IsConfidential ? 'Yes' : 'No' }}
      </div>
    </aecom-form-item>
    <aecom-form-item label="Keywords" columnSpan="2">
      <div class="itemText" style="display: flex; flex-wrap: wrap" *ngIf="sortKeyWords(item?.Topics).length > 0; else notProvide">
        <ng-container *ngFor="let tag of sortKeyWords(item?.Topics)">
          <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
            <div class="tag">
              {{tag}}
            </div>
          </div>
        </ng-container>
      </div>
    </aecom-form-item>
  </aecom-form-group>
  <aecom-form-group columnSpan="12" columns="1" title="Workflow History">
    <!-- <timeline [data]="timelineItems"></timeline> -->
    <workflowHistory [transmittal]="transmittal" [docs]="docs"></workflowHistory>
  </aecom-form-group>    
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>

<ng-template #notProvide>
  <div class="itemText">-</div>
</ng-template>

<ng-template #hideItem>
  <aecom-form-item columnSpan="1">
  </aecom-form-item>
</ng-template>