import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import _ from "lodash";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  CorrespondenceRefrenceUserService,
  TransmittalItemService,
} from "@api";
import LoadingService from "@services/loading.service";
import RouteParams from "@shared/route-params";
import IListPrepare from "@models/IListPrepaer";
import LocalContractUserService from "@services/local-contractUser.service";
import { validDBViewPermission } from "@shared/utils";
import NavService from "@services/nav.service";

@Injectable({
  providedIn: "root",
})
export default class ListResolver {
  constructor(
    public router: Router,
    public loadingService: LoadingService,
    public transmittalItemService: TransmittalItemService,
    public correspondenceRefrenceUserService: CorrespondenceRefrenceUserService,
    public activatedRoute: ActivatedRoute,
    public localContractUserService: LocalContractUserService,
    public navService: NavService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IListPrepare> {
    this.loadingService.start();
    const contractId = route.parent.params[RouteParams.ContractId];

    return forkJoin([
      this.transmittalItemService.getAllTransmittalItems(contractId),
      this.correspondenceRefrenceUserService.getAllCorrespondenceRefrenceUsers(),
    ]).pipe(
      map(([res, refUsers]) => {
        const filteredItems = validDBViewPermission(
          res,
          this.localContractUserService,
          this.navService.getUser()
        );

        const items = _.orderBy(
          _.orderBy(filteredItems, "LetterDate", ["desc"]),
          "ViewOnly",
        );
        this.loadingService.stop();
        return { items, refUsers };
      }),
      catchError((error: HttpErrorResponse) => {
        this.router.navigate([
          contractId,
          "error",
          error.status ? error.status : 500,
        ]);
        return of(null);
      }),
    );
  }
}
