import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export default class LoadingService {
  turnOffCanDeactive: boolean;
  constructor(private spinner: NgxSpinnerService) {}

  start(): void {
    this.spinner.show("spinner");
  }

  stop(turnOff?: boolean): void {
    this.spinner.hide("spinner");
    this.turnOffCanDeactive = turnOff;
  }
}
