<div class="container-fluid" *ngIf="transmittal">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex">
          <div class="header-title">
            <p>
              <a [routerLink]="['/' + contractId + '/list']"> Transmittal List &gt; </a>
              <span> {{ transmittal.Guid ? "Draft Transmittal" : "Create New Transmittal" }}</span>
            </p>

            <h1>
              <i class="icon icon-lg icon-pencil"></i>

              <span style="text-transform: uppercase">{{ title }}</span>
            </h1>
          </div>
        </div>

        <div class="col-6" style="padding: 0">
          <div class="header-actions">
            <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()"> back </aecom-button>

            <aecom-button
              [theme]="'light'"
              [disabled]="!hasChanges() ? true : null"
              innerClass="btn lg solid green"
              (click)="saveDraft()"
            >
              save draft
            </aecom-button>

            <aecom-button data-cy="submit" [theme]="'light'" innerClass="btn lg solid blue" (click)="submit()"> submit </aecom-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <aecom-form-group
          title="General"
          columnSpan="6"
          columns="2"
          style="display: block; margin-bottom: 10px; min-height: 60%"
        >
          <aecom-form-item [label]="'From (Originator)'" [required]="true" columnSpan="1">
            <aecom-dropdown
            data-cy="from"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="fromList"
              [selectedId]="transmittal.transmittal_create.From"
              [validate]="attemptToIssue && !transmittal.transmittal_create.From"
              (ngValueChange)="setFromUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'To (Recipient)'" [required]="true" columnSpan="1">
            <aecom-dropdown
            data-cy="to"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="toList"
              [selectedId]="transmittal.transmittal_create.To"
              [validate]="attemptToIssue && !transmittal.transmittal_create.To"
              (ngValueChange)="setToUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Issue Date'" [required]="true" columnSpan="1">
            <aecom-date
              data-cy="letterDate"
              [theme]="'light'"
              [ngValue]="LetterDate"
              (change)="setLetterDate($event)"
              [validate]="!LetterDate && attemptToIssue"
            ></aecom-date>

            <div
              class="error-message"
              *ngIf="!LetterDate && attemptToIssue"
            >
              Please choose issue date.
            </div>
          </aecom-form-item>
          <aecom-form-item [label]="'Organization Reference Number'" columnSpan="1">
            <aecom-textbox
            data-cy="referenceNumber"
              [theme]="'light'"
              [(ngValue)]="transmittal.transmittal_create.OrganizationReferenceNumber"
              (ngValueChange)="setOrgnizationReferenceNumber($event)"
              [validate]="baseFunctionService.isInputInvalid(transmittal.transmittal_create.OrganizationReferenceNumber)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Document Type'" [required]="true" columnSpan="1" *ngIf="!isDB">
            <aecom-dropdown
            data-cy="documentType"
              [theme]="'light'"
              placeholder="Select"
              [data]="documentTypeList"
              [selectedId]="transmittal.transmittal_create.DocumentTypeId"
              [validate]="attemptToIssue && !transmittal.transmittal_create.DocumentTypeId"
              (ngValueChange)="setDocuemntType($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Transmittal Detail'" columnSpan="1" *ngIf="!isDB && showTransmittalDetail()">
            <aecom-dropdown
            data-cy="transmittalDetail"
              [theme]="'light'"
              placeholder="Select"
              [data]="transmittalDetailList"
              [selectedId]="transmittal.transmittal_create.TransmittalDetailId"
              (ngValueChange)="setTransmittalDetail($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Project Reference'" [required]="true" columnSpan="2" [property]="getReferenceLength() + '/' + referenceMaxLength + ' characters'">
            <aecom-textbox
              data-cy="reference"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="referenceMaxLength"
              [(ngValue)]="transmittal.transmittal_create.Reference"
              (ngValueChange)="setRefernce($event)"
              [validate]="attemptToIssue && (!transmittal.transmittal_create.Reference || transmittal.transmittal_create.Reference.trim()==='' || getReferenceLength() > referenceMaxLength) || baseFunctionService.isInputInvalid(transmittal.transmittal_create.Reference)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Subject/Title'" [required]="true" columnSpan="2" [property]="getSubjectLength() + '/' + subjectMaxLength + ' characters'">
            <aecom-textbox
              data-cy="subject"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="subjectMaxLength"
              [(ngValue)]="transmittal.transmittal_create.Subject"
              (ngValueChange)="setSubject($event)"
              [validate]="attemptToIssue && (!transmittal.transmittal_create.Subject || transmittal.transmittal_create.Subject.trim()==='' || getSubjectLength() > subjectMaxLength) || baseFunctionService.isInputInvalid(transmittal.transmittal_create.Subject)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item columnSpan="1" [required]="true" [label]="'Transmittal File'">
            <aecom-upload
              data-cy="transmittalFile"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="bbjFileListService.getFileList(transmittalFileType.TransmittalFile).fileList$ | async"
              [theme]="'light'"
              (ngValueChange)="baseFunctionService.importFile($event, transmittalFileType.TransmittalFile)"
              (ngDeleteChange)="baseFunctionService.deleteFile($event, transmittalFileType.TransmittalFile)"
            ></aecom-upload>
            <div class="error-message" *ngIf="bbjFileListService.getFileList(transmittalFileType.TransmittalFile).getCurrentFiles().length === 0 && attemptToIssue">Required Field</div>
            <div class="error-message" *ngIf="!(bbjFileListService.getFileList(transmittalFileType.TransmittalFile).fileListNameValid$ | async)">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>

          <aecom-form-item columnSpan="1" [label]="'Attachments'">
            <aecom-upload
              data-cy="attachments"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="bbjFileListService.getFileList(transmittalFileType.Attachment).fileList$ | async"
              [theme]="'light'"
              (ngValueChange)="baseFunctionService.importFile($event, transmittalFileType.Attachment)"
              (ngDeleteChange)="baseFunctionService.deleteFile($event, transmittalFileType.Attachment)"
            ></aecom-upload>
            <div class="error-message" *ngIf="!(bbjFileListService.getFileList(transmittalFileType.Attachment).fileListNameValid$ | async)">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>
        </aecom-form-group>
        <div *ngIf="isDB" style="grid-column: span 6;">
          <ng-container *ngTemplateOutlet="ccSession">
          </ng-container>
        </div>
        
        <aecom-form-group
          *ngIf="!isDB"
          columnSpan="6"
          columns="2"
          title="Processing"
          style="position: relative"
        >
          <aecom-form-item
            data-cy="requireResponse"
            label="Does this Transmittal specifically request a response?"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                id="requireResponseYes"
                innerClass="btn solid btn-tab {{
                  transmittal.transmittal_issued.RequireResponse
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setRequireResponse(true)"
              >
                Yes
              </aecom-button>

              <aecom-button
                id="requireResponseNo"
                innerClass="btn solid btn-tab {{
                  !transmittal.transmittal_issued.RequireResponse
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setRequireResponse(false)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
          <aecom-form-item label="Due Date" columnSpan="1" *ngIf="transmittal.transmittal_issued.RequireResponse; else skipItem">
            <aecom-date
              id="due"
              data-cy="due"
              [weekendsOff]="true"
              [notValidBefore]="today"
              [theme]="'light'"
              [ngValue]="dueDate"
              (change)="setDate($event)"
            >
            </aecom-date>
          </aecom-form-item>
          <aecom-form-item
            data-cy="receivedToday"
            label="Was this Transmittal received today?"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                id="receivedToadyYes"
                innerClass="btn solid btn-tab {{
                  transmittal.transmittal_issued.ReceivedToday
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setReceivedToday(true)"
              >
                Yes
              </aecom-button>

              <aecom-button
                id="receivedTodayNo"
                innerClass="btn solid btn-tab {{
                  !transmittal.transmittal_issued.ReceivedToday
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setReceivedToday(false)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
          <aecom-form-item label="Received Date" columnSpan="1" *ngIf="!transmittal.transmittal_issued.ReceivedToday; else skipItem">
            <aecom-date
              id="receivedDate"
              data-cy="receivedDate"
              [theme]="'light'"
              [ngValue]="receivedDate"
              (change)="setReceivedDate($event)"
              [notValidAfter]="today"
            >
            </aecom-date>
          </aecom-form-item>
          <aecom-form-item
            data-cy="isConfidential"
            label="Is this Transmittal confidential?"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                id="isConfidentialYes"
                innerClass="btn solid btn-tab {{
                  transmittal.transmittal_issued.IsConfidential
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setIsConfidential(true)"
              >
                Yes
              </aecom-button>

              <aecom-button
                id="isConfidentialNo"
                innerClass="btn solid btn-tab {{
                  !transmittal.transmittal_issued.IsConfidential
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setIsConfidential(false)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
          <aecom-form-item columnSpan="1">
          </aecom-form-item>
          <aecom-form-item
            label="Keywords"
            columnSpan="1"
          >
            <bbj-tag id="topic" data-cy="topic" [tagsIn]="transmittal.transmittal_issued.Topics" [allTags]="allTags" (tagsInChange)="setTags($event)"></bbj-tag>
          </aecom-form-item>
          <div style="grid-column: span 2; margin-top: 17px;">
            <ng-container *ngTemplateOutlet="ccSession">
            </ng-container>
          </div>
        </aecom-form-group>
      </aecom-form>
    </div>
  </div>
</div>

<div *ngIf="bbjSelectUserService.workingItem" class="up-contentcontainer">
  <div class="selectUserWindow">
    <bbj-select-user></bbj-select-user>
  </div>
</div>

<ng-template #ccSession>
  <bbj-selectedUsersTable [title]="userSelectTitle"></bbj-selectedUsersTable>
  <aecom-form-item [label]="'Additional Notes'" columnSpan="2" [property]="getNotesLength() + '/' + notesMaxLength + ' characters'">
    <aecom-textbox
      data-cy="note"
      rows="3"
      [theme]="'light'"
      [isMultiLine]="true"
      [maxlength]="notesMaxLength"
      [(ngValue)]="transmittal.transmittal_create.Note"
      (ngValueChange)="setNote($event)"
      [validate]="attemptToIssue && getNotesLength() > notesMaxLength || baseFunctionService.isInputInvalid(transmittal.transmittal_create.Note)"
    ></aecom-textbox>
  </aecom-form-item>
</ng-template>

<ng-template #skipItem>
  <aecom-form-item columnSpan="1"></aecom-form-item>
</ng-template>