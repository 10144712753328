import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import CreateComponent from "@content/transmittal/create/create.component";
import IssuedComponent from "@content/transmittal/issued/issued.component";
import ListComponent from "@content/transmittal/list/list.component";
import ViewComponent from "@content/transmittal/view/view.component";
import { PendingChangesGuard } from "@guards/pending-changes.guard";
import CreateStepResolver from "@resolvers/createStep.resolver";
import ListResolver from "@resolvers/list.resolver";
import ViewResolver from "@resolvers/view.resolver";
import environment from "src/environments/environment";
import ErrorComponent from "@content/error/error.component";
import ErrorResolve from "@resolvers/error-resolver";
import BaseComponent from "./content/base/base.component";
import SignoutComponent from "./content/signout/signout.component";
import LoginUserResolve from "./resolvers/login-user-resolver";
import RouteParams from "./shared/route-params";
import { BrowserUtils } from "@azure/msal-browser";

const routes: Routes = [
  {
    path: `error/:${RouteParams.ErrorCode}`,
    component: ErrorComponent,
    canActivate: [MsalGuard],
    resolve: { loginuser: ErrorResolve },
  },
  {
    path: "sign-out",
    component: SignoutComponent,
    pathMatch: "full",
  },
  {
    path: "logout",
    component: SignoutComponent,
    pathMatch: "full",
  },
  {
    path: `:${RouteParams.ContractId}`,
    component: BaseComponent,
    canActivate: [MsalGuard],
    runGuardsAndResolvers: "always",
    resolve: { loginuser: LoginUserResolve },
    children: [
      {
        path: "list",
        component: ListComponent,
        runGuardsAndResolvers: "always",
        canActivate: [MsalGuard],
        resolve: { listItems: ListResolver },
      },
      {
        path: `create/:${RouteParams.ItemId}`,
        component: CreateComponent,
        runGuardsAndResolvers: "always",
        canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard],
        resolve: { itemData: ViewResolver, createStepData: CreateStepResolver },
      },
      {
        path: `issued/:${RouteParams.ItemId}`,
        component: IssuedComponent,
        runGuardsAndResolvers: "always",
        canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard],
        resolve: { itemData: ViewResolver },
      },
      {
        path: `view/:${RouteParams.ItemId}`,
        component: ViewComponent,
        runGuardsAndResolvers: "always",
        canActivate: [MsalGuard],
        resolve: { itemData: ViewResolver },
      },
      {
        path: `error/:${RouteParams.ErrorCode}`,
        component: ErrorComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "",
        redirectTo: "list",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "**",
    redirectTo:
      environment.url.logoutURL.includes("localhost") ||
      environment.url.logoutURL.includes("http://127.0.0.1")
        ? "037c7f4d-0562-46b1-ac09-d4c35c0d9705/list"
        : environment.url.portal,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export default class AppRoutingModule {}
