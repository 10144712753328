/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin, of } from "rxjs";
import { CorrespondenceRefrenceUserService, TransmittalItemService } from "@api";
import ICreatePrepare from "@models/ICreatePrepaer";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import RouteParams from "@shared/route-params";

@Injectable({
  providedIn: "root"
})
export default class CreateStepResolver {
  constructor(
    public router: Router,
    private transmittalItemService: TransmittalItemService,
    private correspondenceRefrenceUserService: CorrespondenceRefrenceUserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICreatePrepare> {
    const contractId = route.parent.params[RouteParams.ContractId];
    return forkJoin([
      this.correspondenceRefrenceUserService.getAllCorrespondenceRefrenceUsers(),
      this.transmittalItemService.getTransmittalTopics(),
    ]).pipe(
      map(([refUsers, topicItems]) => {
        const topics = topicItems.map((t)=>t.Name);
        return { refUsers, topics };
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate([contractId, "error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }
}
